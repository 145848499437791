import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import formatStringByPattern from 'format-string-by-pattern';
import moment from 'moment';
import * as R from 'ramda';
import React from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';
import StringMask from 'string-mask';
import walk from './walk';

export const dotPhoneFormatter = new StringMask('(00) 0 0000.0000');
export const dotTelephoneFormatter = new StringMask('(00) 0000.0000');
export const phoneFormatter = new StringMask('(00) 00000-0000');
export const telephoneFormatter = new StringMask('(00) 0000-0000');
export const cpfFormatter = new StringMask('000.000.000-00');

// eslint-disable-next-line @typescript-eslint/no-var-requires
const CPF = require('cpf');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const CNPJ = require('cnpj');

export const dotPhoneFormat = (value) => {
  value = phoneUnmask(value);
  if (value === '') return 'Não cadastrado';
  return dotPhoneFormatter.apply(value);
};

export const dotTelephoneFormat = (value) => {
  value = phoneUnmask(value);
  if (value === '') return 'Não cadastrado';
  return dotTelephoneFormatter.apply(value);
};

export const defaultPhoneFormat = (value) => {
  value = phoneUnmask(value);
  if (value === '') return 'Não cadastrado';
  return phoneFormatter.apply(value);
};

export const defaultPhoneFormatWithDDI = (telefone) => {
  if (!telefone) {
    return 'Não cadastrado';
  }
  const { numero, ddi } = telefone;
  const numberWithDDI = `+${ddi}${numero}`;
  if (numberWithDDI === '') return 'Não cadastrado';
  return ddi !== '55'
    ? '+' + ddi + ' ' + formatPhoneNumber(numberWithDDI)
    : formatPhoneNumber(numberWithDDI);
};

export const defaultTelephoneFormat = (value) => {
  value = phoneUnmask(value);
  if (value === '') return 'Não cadastrado';
  return telephoneFormatter.apply(value);
};

export const dotContactFormat = (value, notInformed = 'Não cadastrado') => {
  const digits = phoneUnmask(value);
  if (digits.length === 10) {
    return dotTelephoneFormatter.apply(digits);
  } else if (digits.length === 11) {
    return dotPhoneFormatter.apply(digits);
  }
  return notInformed;
};

export const defaultContactFormat = (value, notInformed = 'Não cadastrado') => {
  const digits = phoneUnmask(value);
  if (digits.length === 10) {
    return telephoneFormatter.apply(digits);
  } else if (digits.length === 11) {
    return phoneFormatter.apply(digits);
  }
  return notInformed;
};

export const phoneFormatWithoutPrefixCountry = (value) => {
  const prefix = '+55';
  return defaultPhoneFormat(value.replace(prefix, ''));
};

export const telephoneFormatWithoutPrefixCountry = (value) => {
  const prefix = '+55';
  return defaultTelephoneFormat(value.replace(prefix, ''));
};

export const dotPhoneFormatWithoutPrefixCountry = (value) => {
  const prefix = '+55';
  return dotPhoneFormat(value.replace(prefix, ''));
};

export const dotTelephoneFormatWithoutPrefixCountry = (value) => {
  const prefix = '+55';
  return dotTelephoneFormat(value.replace(prefix, ''));
};

export const isEmail = (email = null) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const phoneUnmask = (value) => {
  if (value === undefined || value === null) return '';
  return value.replace(/\D/g, '');
};

export const validateOnlyCellphone = (cellphone) => {
  if (cellphone === undefined || cellphone === '') return 'Celular Obrigatório';
  if (cellphone !== undefined) {
    const celLength = phoneUnmask(cellphone).length;
    if (celLength === 11) return undefined;
    if (celLength !== 11) return 'Celular Inválido';
  }
};

export const validateOnlyTelephone = (telephone) => {
  if (telephone === undefined || telephone === '')
    return 'Telefone Obrigatório';
  if (telephone !== undefined) {
    const telLength = phoneUnmask(telephone).length;
    if (telLength === 10) return undefined;
    if (telLength !== 10) return 'Telefone Inválido';
  }
};

export const validateCellphone = (cell, tel) => {
  if (
    (cell === undefined && tel === undefined) ||
    (cell === '' && tel === '') ||
    (cell === undefined && tel === '') ||
    (cell === '' && tel === undefined)
  )
    return 'Celular ou Telefone Obrigatório';
  if (tel !== undefined) {
    if (phoneUnmask(tel).length === 10 && (cell === '' || cell === undefined))
      return undefined;
  }
  if (cell !== undefined) {
    const celLength = phoneUnmask(cell).length;
    if (celLength === 11) return undefined;
    if ((celLength < 11 && celLength >= 1) || cell === '(__) _____-____')
      return 'Celular Inválido';
  }
};

export const validateTelephone = (cell, tel) => {
  if (
    (cell === undefined && tel === undefined) ||
    (cell === '' && tel === '') ||
    (cell === undefined && tel === '') ||
    (cell === '' && tel === undefined)
  )
    return 'Celular ou Telefone Obrigatório';
  if (!R.isNil(cell)) {
    if (phoneUnmask(cell).length === 11 && (tel === '' || tel === undefined))
      return undefined;
  }
  if (!R.isNil(tel)) {
    const telLength = phoneUnmask(tel).length;
    if (telLength === 10) return undefined;
    if ((telLength < 10 && telLength >= 1) || tel === '(__) ____-____')
      return 'Telefone Inválido';
  }
};

export const validateEmail = (email) => {
  if (email === '' || email === undefined) return 'Obrigatório';
  if (!isEmail(email)) return 'E-mail Inválido';
  return undefined;
};

export const monthToString = (month) => {
  switch (month) {
    case 1:
      return 'Janeiro';
    case 2:
      return 'Fevereiro';
    case 3:
      return 'Março';
    case 4:
      return 'Abril';
    case 5:
      return 'Maio';
    case 6:
      return 'Junho';
    case 7:
      return 'Julho';
    case 8:
      return 'Agosto';
    case 9:
      return 'Setembro';
    case 10:
      return 'Outubro';
    case 11:
      return 'Novembro';
    case 12:
      return 'Dezembro';
    default:
      return '';
  }
};

export const formatDate = (date) => {
  return date != null && date !== '' ? moment(date).format('DD/MM/YYYY') : '';
};

export const formatDateTime = (date) => {
  return date != null && date !== ''
    ? moment(date).format('DD/MM/YYYY HH:mm')
    : '';
};

export const formatDateMinYear = (date) => {
  return date != null && date !== '' ? moment(date).format('DD/MM/YY') : '';
};

export const formatDateDiaMesAnoHora = (date) => {
  return date != null && date !== ''
    ? moment(date).format(
        `DD [de] [${monthToString(moment(date).month() + 1)}] [de] YYYY [às] HH:mm`,
      )
    : '';
};

export const yupValidateTelephone = (value) => {
  const telephoneFormat = /\(\d{2,2}\) \d{4,4}-\d{4,4}/g;
  const telephone = /\d{10,10}/g;
  const formattedTelephoneRegex = telephoneFormat.test(value);
  const telephoneRegex = telephone.test(value);
  return (
    formattedTelephoneRegex ||
    telephoneRegex ||
    R.isNil(value) ||
    R.isEmpty(value)
  );
};

export const yupValidateCellphone = (value) => {
  const cellphoneFormat = /\(\d{2,2}\) \d{5,5}-\d{4,4}/g;
  const cellphone = /\d{11,11}/g;
  const formattedCellphoneRegex = cellphoneFormat.test(value);
  const cellphoneRegex = cellphone.test(value);
  return (
    formattedCellphoneRegex ||
    cellphoneRegex ||
    R.isNil(value) ||
    R.isEmpty(value)
  );
};

export const yupValidateCellphoneOrTelephone = (value) => {
  value = phoneUnmask(value);
  const cellphoneFormat = /\(\d{2,2}\) \d{5,5}-\d{4,4}/g;
  const cellphone = /\d{11,11}/g;
  const formattedCellphoneRegex = cellphoneFormat.test(value);
  const cellphoneRegex = cellphone.test(value);

  const telephoneFormat = /\(\d{2,2}\) \d{5,5}-\d{3,3}/g;
  const telephone = /\d{10,10}/g;
  const formattedTelephoneRegex = telephoneFormat.test(value);
  const telephoneRegex = telephone.test(value);

  return (
    formattedCellphoneRegex ||
    cellphoneRegex ||
    formattedTelephoneRegex ||
    telephoneRegex ||
    R.isNil(value) ||
    R.isEmpty(value)
  );
};

export const yupValidateZipCode = (value) => {
  if (value && value.startsWith('00')) return false;
  const zipCodeFormat = /\d{5,5}-\d{3,3}/g;
  const zipCode = /\d{8,8}/g;
  const formattedZipCodeRegex = zipCodeFormat.test(value);
  const zipCodeRegex = zipCode.test(value);
  return formattedZipCodeRegex || zipCodeRegex || R.isNil(value);
};
export const unflatObject = R.pipe(
  R.toPairs,
  R.reduce((result, currentKeyValue) => {
    const [field, value] = currentKeyValue;
    return R.assocPath(R.split('.', field), value, result);
  }, {}),
);

export const formatToReal = (number) => {
  if (number == null) {
    return '';
  }

  const numero = Number(number).toFixed(2).split('.');
  numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
  return numero.join(',');
};

export const formatDecimals = (number) => formatToReal(number);

export const formatCentavosToReal = (valorCentavos) => {
  const valor = Number(valorCentavos / 100);
  return formatToReal(valor);
};

export const formatFilters = (values, defaultFilters) => {
  const copy = {
    ...values,
  };
  const filters = Object.fromEntries(
    Object.entries(defaultFilters)
      .filter(([item, field]) => copy[item]?.id > 0)
      .map(([item, field]) => {
        const filter = [field, copy[item]?.id];
        delete copy[item];
        return filter;
      }),
  );
  return {
    ...copy,
    ...filters,
  };
};

export const normalizeTitle = (tituloImovel) => {
  if (tituloImovel.includes('Apartamento')) {
    const [predio, apartamento] = tituloImovel.split('|');
    return `${predio}, ${apartamento}`;
  } else {
    return tituloImovel.split('|')[0];
  }
};

export const onlyNumbers = (value) => {
  if (value === undefined || value === null) return '';
  return value.replace(/\D/g, '');
};
export const yupValidateCPF = (value) => {
  if (!value) return true;
  return CPF.isValid(onlyNumbers(value));
};
export const yupValidateCNPJ = (value) => {
  if (!value) return true;
  return CNPJ.validate(onlyNumbers(value));
};
export const yupValidateCPFCNPJ = (value) => {
  if (!value) return true;
  if (value.replace(/\D/g, '').length <= 11)
    return CPF.isValid(onlyNumbers(value));
  return CNPJ.validate(onlyNumbers(value));
};
export const getLabelFromEnumList = (value, enumList) => {
  const item = enumList.find((item) => item.value === value);
  return item ? item.label : '';
};

export const joinDateTime = (date, time) => {
  const dateTime = moment(date);
  const momentTime = moment(time);
  dateTime.set({
    hour: momentTime.get('hour'),
    minute: momentTime.get('minute'),
  });
  return dateTime;
};

export const setTimeStringIntoDate = (date, time) => {
  const [hour, minute] = time.split(':');
  date.set({ hour, minute });
  return date;
};

export const compareTime = (first, second) => {
  if (moment(first, 'HH:mm').isBefore(moment(second, 'HH:mm'))) {
    return -1;
  }
  return moment(first, 'HH:mm').isAfter(moment(second, 'HH:mm')) ? 1 : 0;
};

export const formatQuantity = (number) => {
  if (!number || isNaN(number)) return null;
  if (number < 10) {
    return `0${number}`;
  }
  return number;
};

export const telefoneMask = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const telefoneFixoMask = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const CPFMask = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const CNPJMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const cpfFormat = (value) => {
  if (!value) return 'Não cadastrado';
  return cpfFormatter.apply(value.replace(/\D/g, ''));
};

export const formatCnpjCpf = (value) => {
  if (!value) return 'Não informado';
  const cnpjCpf = value.replace(/\D/g, '');

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  return cnpjCpf.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5',
  );
};

export const yupValidateRG = (value) => {
  return /^[0-9a-z,./-]*$/i.test(value);
};

export const validateDate = (value) => {
  if (!value) return undefined;
  const ptFormat = moment(value, 'DD/MM/yyyy')?._isValid;
  const defaultFormat = moment(value, 'yyyy-MM-DD')?._isValid;
  return value._isValid || ptFormat || defaultFormat
    ? undefined
    : 'Data Inválida';
};

export const validatePastDate = (value) => {
  if (!value) return undefined;
  const isSameOrAfter = moment(
    moment(value).format('YYYY-MM-DD'),
  ).isSameOrAfter(moment().format('YYYY-MM-DD'));
  return isSameOrAfter ? undefined : 'Data não pode ser passada';
};

export const sumNotNull = (values) => {
  return values
    .map((value) => +value || 0)
    .reduce((first, second) => first + second, 0);
};

export const isNumeric = (value) => {
  return /^-{0,1}\d+$/.test(value);
};

export const formatPhone = (value) => {
  return value
    ? value.length > 10
      ? defaultPhoneFormat(value)
      : defaultTelephoneFormat(value)
    : null;
};

// eslint-disable-next-line react/display-name
export const getBoolSymbol = (boolFunction) => (value) => {
  return boolFunction(value) ? (
    <CheckIcon style={{ fill: 'green' }} />
  ) : (
    <CloseIcon style={{ fill: 'red' }} />
  );
};

export const validateTermosErrorMessage = (errors) => {
  const isBlocoTextoError = errors.blocos?.some(
    (bloco) => bloco?.configuration?.textValue,
  );
  if (isBlocoTextoError) {
    return 'Obrigatório preencher blocos de texto';
  }
  return validateErrorMessage(errors);
};

export const validateErrorMessage = (errors) => {
  const isObrigatorioError = (error) => {
    if (typeof error === 'string') {
      return error.includes('Obrigatório');
    } else if (typeof error === 'object') {
      return Object.values(error).find((e) => isObrigatorioError(e));
    }
  };

  const isTelefoneInvalidoError = (error) => {
    if (typeof error === 'string') {
      return error.includes('Telefone inválido');
    } else if (typeof error === 'object') {
      return Object.values(error).find((e) => isTelefoneInvalidoError(e));
    }
  };

  const getTelefonesErrorMessage = (error) => {
    if (isTelefoneInvalidoError(error)) return 'Telefone inválido';
    return 'Obrigatório preencher o cadastro de telefone';
  };

  const onlyTelefoneError =
    errors &&
    Object.values(errors).length === 1 &&
    (errors.telefones?.length ||
      errors.proprietario?.telefones ||
      errors.cliente?.telefones);

  const onlyTelefoneAndEmailError =
    errors &&
    Object.values(errors).length === 1 &&
    (errors.telefones?.length ||
      errors.proprietario?.telefones ||
      errors.cliente?.telefones) &&
    errors.proprietario?.email;

  if (onlyTelefoneAndEmailError)
    return 'Obrigatório preencher o cadastro de telefone ou e-mail';
  // Sinalizando erro de telefones somente pela snackbar por enquanto
  else if (onlyTelefoneError) return getTelefonesErrorMessage(errors);

  return errors
    ? Object.values(errors).find((error) => isObrigatorioError(error))
      ? 'Campos obrigatórios não preenchidos'
      : 'Campos preenchidos incorretamente'
    : null;
};

export const removeNullValues = (values) => {
  const predicate = (value) =>
    ![null, undefined, 'Invalid date'].includes(value);
  return R.pickBy(predicate, values);
};

export const formatOnlyNumbers = (anyString, pattern) => {
  const onlyNumbers = anyString?.replace(/[^\d]/g, '');
  return formatStringByPattern(pattern, onlyNumbers);
};

export const sleep = (milliseconds) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), milliseconds);
  });
};

export const formatTime = (time) =>
  time
    ? moment
        .tz(time, 'HH:mm:ss', 'America/Sao_Paulo')
        .tz(moment.tz.guess())
        .format('HH:mm')
    : '';

export const formatTimeHorasMinutos = (time) =>
  time ? moment(time).tz('America/Sao_Paulo').format('HH:mm') : '';

export const clearNullStrings = (obj) => {
  return walk(obj, (value) =>
    value === 'null' || value === '' ? null : value,
  );
};

export const clearEmptyStrings = (obj) => {
  return walk(obj, (value) => (value === '' ? null : value));
};

export const removeEmptyAndNullValues = (obj) => {
  return removeNullValues(
    walk(obj, (value) =>
      value === 'null' || value === '' || R.isEmpty(value) ? null : value,
    ),
  );
};

export const IsImobiliariaParceiraByContext = (context, tenantId) => {
  return context?.user?.tenantId !== tenantId;
};

export const recoverValues = (search) => {
  if (!search) return {};
  const searchParams = new URLSearchParams(search);
  let values = {};
  searchParams.forEach((value, key) => {
    values = {
      ...values,
      [key]: value,
    };
  });
  return values;
};

export const capitalizeFirstLetter = (str) => {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};
